import datepicker from 'js-datepicker';
import Utilities from './utilities';

class Monthpicker {
  static init() {
    const inputs = Array.from(document.querySelectorAll('.js-monthpicker')).filter((el) => !el.nextElementSibling);
    inputs.forEach((el) => {
      datepicker(el, {
        defaultView: 'overlay',
        dateSelected: (!!el.defaultValue && this.createDateObject(el.defaultValue)) || new Date(),
        respectDisabledReadOnly: true,
        disableYearOverlay: true,
        customMonths: [
          'STYCZEŃ',
          'LUTY',
          'MARZEC',
          'KWIECIEŃ',
          'MAJ',
          'CZERWIEC',
          'LIPIEC',
          'SIERPIEŃ',
          'WRZESIEŃ',
          'PAŹDZIERNIK',
          'LISTOPAD',
          'GRUDZIEŃ',
        ],
        formatter: (input, date, instance) => {
          input.value = `${instance.currentMonthName} ${instance.currentYear}`;
        },
        onShow: (instance) => {
          instance.el.previousElementSibling.textContent = `${instance.currentMonthName} ${instance.currentYear}`;
          this.addYearValue(instance.parent, instance.currentYear);
          this.addActiveClass(instance.parent, instance.currentMonthName);
        },
        onMonthChange: (instance) => {
          instance.calendarContainer.classList.add('qs-hidden');
          instance.el.value = this.formatInputDate(instance);
          instance.el.previousElementSibling.textContent = `${instance.currentMonthName} ${instance.currentYear}`;
          this.addActiveClass(instance.parent, instance.currentMonthName);
          this.addYearValue(instance.parent, instance.currentYear);
          Utilities.trigger(instance.el, 'change');
        },
      });
    });
  }

  static addActiveClass(datepickerWrapper, selectedMonth) {
    const months = datepickerWrapper.querySelectorAll('.qs-overlay-month');
    months.forEach((month) => {
      month.textContent === selectedMonth.slice(0, 3) ? month.classList.add('active') : null;
    });
  }

  static addYearValue(datepickerWrapper, selectedYear) {
    const year = datepickerWrapper.querySelector('.qs-overlay-year');
    year.value = selectedYear;
  }

  static formatInputDate(instance) {
    const currentMonth = instance.currentMonth + 1;
    const month = currentMonth < 10 ? `0${currentMonth}` : currentMonth.toString();
    return `01.${month}.${instance.currentYear.toString()}`;
  }

  static createDateObject(value) {
    const formatted = value.split('.').reverse().join('/');
    return new Date(formatted);
  }
}

export default Monthpicker;
