import Choices from 'choices.js';
import Utilities from './utilities';
import debounce from 'debounce';

class General {
  static toggleSidebar() {
    Utilities.handleEvent('.js-toggle-sidebar', 'click', function () {
      document.querySelector('.overlay').classList.toggle('uk-display-block');
      document.querySelector('.js-sidebar').classList.toggle('open');
    });
  }

  static initChoices(root = document) {
    root.querySelectorAll('.js-choices select').forEach(function (elem) {
      const removeButton = elem.dataset.noremove === 'false';
      const disableSort = elem.dataset.disableSort !== 'true';
      const isLazyLoad = elem.dataset.lazy === 'true';

      const choicesElement = new Choices(elem, {
        removeItemButton: removeButton,
        itemSelectText: '',
        shouldSort: disableSort,
        noResultsText: elem.getAttribute('data-noresults'),
        noChoicesText: elem.getAttribute('data-nochoices'),
        searchPlaceholderValue: elem.getAttribute('data-search-placeholder-value'),
        classNames: {
          noChoices: 'has-no-choices uk-hidden',
        },
      });

      if (isLazyLoad) {
        const url = elem.dataset.lazyUrl;
        const errorText = elem.dataset.fetchErrorText;

        elem.addEventListener(
          'search',
          debounce((ev) => {
            Rails.ajax({
              url: url,
              type: 'GET',
              data: `search=${ev.detail.value}`,
              success: (res) => {
                choicesElement.setChoices(res.data, res.value_key, res.label_key, true);
              },
              error: (err) => Notifications.display(errorText, 'danger'),
            });
          }, 500),
          false
        );
      }
    });
  }

  static headerInfiniteScroll() {
    const dropdown = document.querySelector('.js-clients-dropdown');
    const nextPage = dropdown.querySelector('.js-clients-next-page');
    if (!nextPage) return;

    Utilities.handleEvent('.js-clients-dropdown', 'scroll', function () {
      if (this.scrollTop === this.scrollHeight - this.offsetHeight) {
        if (!nextPage) return;

        Rails.fire(nextPage, 'click');
      }
    });
  }

  static addLoader() {
    Utilities.handleEvent('.js-add-loader', 'click', function () {
      document.querySelector('.app-container').classList.add('loader');
      document.querySelector('.js-spinner').classList.remove('uk-hidden');
    });
  }

  static removeLoader() {
    document.querySelector('.app-container').classList.remove('loader');
    document.querySelector('.js-spinner').classList.add('uk-hidden');
  }

  static hideModal() {
    Utilities.handleEvent('.js-hide-modal', 'click', function () {
      const modalName = this.getAttribute('data-modal');
      const modal = document.querySelector(modalName);
      if (!modalName || !modal) return;

      UIKit.modal(modalName).hide();
    });
  }

  static blockActiveLinkRequest() {
    Utilities.handleEvent('.js-request-button', 'click', function (e) {
      if (!this.parentElement.classList.contains('uk-active')) return;

      e.preventDefault();
      e.stopImmediatePropagation();
    });
  }
}

export default General;
