import datepicker from 'js-datepicker';
import Utilities from './utilities';

class Datepicker {
  static init() {
    const inputs = Array.from(document.querySelectorAll('.js-datepicker')).filter(
      (el) => !el.nextElementSibling?.classList.contains('qs-datepicker-container')
    );

    inputs.forEach((el) => {
      datepicker(el, {
        dateSelected: (!!el.value && this.createDateObject(el.value)) || new Date(),
        showAllDates: true,
        disableYearOverlay: true,
        startDay: 1,
        customDays: ['ND', 'PN', 'WT', 'ŚR', 'CZ', 'PT', 'SB'],
        customMonths: [
          'STYCZEŃ',
          'LUTY',
          'MARZEC',
          'KWIECIEŃ',
          'MAJ',
          'CZERWIEC',
          'LIPIEC',
          'SIERPIEŃ',
          'WRZESIEŃ',
          'PAŹDZIERNIK',
          'LISTOPAD',
          'GRUDZIEŃ',
        ],
        formatter: (input, _date, instance) => {
          input.value = this.formatInputDate(instance);
        },
        onSelect: (instance, date) => {
          Utilities.trigger(instance.el, 'change');
        },
      });
    });
  }

  static formatInputDate(instance) {
    const currentDay =
      instance.dateSelected.getDate() < 10 ? `0${instance.dateSelected.getDate()}` : instance.dateSelected.getDate();
    const currentMonth = instance.currentMonth + 1;
    const month = currentMonth < 10 ? `0${currentMonth}` : currentMonth.toString();
    return `${currentDay}.${month}.${instance.currentYear.toString()}`;
  }

  static createDateObject(value) {
    const formatted = value.split('.').reverse().join('/');
    return new Date(formatted);
  }
}

export default Datepicker;
