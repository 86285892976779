import Datepicker from './datepicker';

class Services {
  static toggleImportButton() {
    const selectedCheckboxesCount = Array.from(document.querySelectorAll('.js-import-service-checkbox')).filter(
      (el) => el.checked
    ).length;
    const importButton = document.querySelector('.js-import-services-submit');
    selectedCheckboxesCount > 0
      ? importButton.classList.remove('imker-button--disabled')
      : importButton.classList.add('imker-button--disabled');
  }

  static importButtonEvent() {
    Utilities.handleEvent('.js-import-service-checkbox', 'change', Services.toggleImportButton);
  }

  static handleCocoonAfter() {
    Datepicker.init();
    Services.calculateInputsValue();
  }

  static initDatepickerAfterCocoonEvents() {
    Utilities.handleEvent('.js-service-insert-entries', 'cocoon:after-insert', Services.handleCocoonAfter);
    Utilities.handleEvent('.js-service-insert-entries', 'cocoon:after-remove', Services.handleCocoonAfter);
    Utilities.handleEvent('.js-service-insert-entries-modal', 'cocoon:after-insert', Services.handleCocoonAfter);
    Utilities.handleEvent('.js-service-insert-entries-modal', 'cocoon:after-remove', Services.handleCocoonAfter);
  }

  static handleServiceAmountKeyup() {
    const row = Utilities.closestParentWithClass(this, 'js-service-row');
    const valueField = row.querySelector('.js-service-value');
    const totalValueField = row.querySelector('.js-service-total');

    totalValueField.textContent = (parseFloat(this.value || '0') * parseFloat(valueField.value || '0')).toFixed(2);
  }

  static handleServiceValueKeyup() {
    const row = Utilities.closestParentWithClass(this, 'js-service-row');
    const amountField = row.querySelector('.js-service-amount');
    const totalValueField = row.querySelector('.js-service-total');

    totalValueField.textContent = (parseFloat(this.value || '0') * parseFloat(amountField.value || '0')).toFixed(2);
  }

  static calculateInputsValue() {
    Utilities.handleEvent('.js-service-amount', 'keyup', Services.handleServiceAmountKeyup);
    Utilities.handleEvent('.js-service-value', 'keyup', Services.handleServiceValueKeyup);

    const rows = document.querySelectorAll('.js-service-row');
    rows.forEach(function (row) {
      const amount = row.querySelector('.js-service-amount').value;
      const unitValue = row.querySelector('.js-service-value').value;
      const totalValueField = row.querySelector('.js-service-total');

      totalValueField.textContent = (parseFloat(unitValue || '0') * parseFloat(amount || '0')).toFixed(2);
    });
  }

  static enableRow() {
    Utilities.handleEvent('.js-edit-row', 'click', function () {
      const tr = this.closest('tr');
      const inputs = tr.querySelectorAll('.imker-input--disabled');

      this.classList.add('uk-hidden');
      inputs.forEach((input) => input.classList.remove('imker-input--disabled'));
    });
  }
}

export default Services;
