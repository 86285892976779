import Utilities from './utilities';

class Tags {
  static handleInput() {
    Utilities.handleEvent('.js-tags-input', 'input', function (e) {
      const existingTags = document.querySelector('.js-existing-tags');
      const tagsDropdown = document.querySelector('.js-tags-dropdown');
      const tags = existingTags.getAttribute('data-tags');
      const tagsArray = tags.split(';');
      const inputValue = e.target.value;
      let result = [];

      if (inputValue !== '') {
        result = tagsArray.filter((tag) => tag.toLowerCase().includes(inputValue.toLowerCase()));
      }

      if (result.length === 0) {
        tagsDropdown.classList.add('uk-hidden');
        return;
      }
      tagsDropdown.innerHTML = '';
      result.forEach((elem) => {
        if (!tagsDropdown.querySelector(`li[data-tag='${elem}']`)) {
          const li = document.createElement('li');
          li.setAttribute('data-tag', elem);
          li.innerHTML = elem;
          tagsDropdown.append(li);
        }
      });
      tagsDropdown.classList.remove('uk-hidden');
    });
  }
}

export default Tags;
