import Utilities from './utilities';

class PriceLists {
  static handleOptionClick(e) {
    const optionId = this.getAttribute('data-id');
    const checkbox = this.querySelector('.js-price-list-option-checkbox');
    const tab = document.querySelector(`.js-price-list-tab[data-id='${optionId}']`);
    const body = document.querySelector(`.js-price-list-body[data-id='${optionId}']`);
    const checkboxCount = Array.from(document.querySelectorAll('.js-price-list-option-checkbox')).filter(
      (checkbox) => checkbox.checked
    ).length;
    const container = document.querySelector('.js-price-list-container');
    const item = document.querySelector(`li.js-price-list-body[data-id='${optionId}']`);
    const checkboxActive = item.querySelector('.js-price-list-option-active');

    const params = {
      tab: tab,
      body: body,
      checkbox: checkbox,
      checkboxActive: checkboxActive,
      checkboxCount: checkboxCount,
      container: container,
    };
    checkbox.checked ? PriceLists.hideFormData(params) : PriceLists.showFormData(params);
  }

  static toggleForm() {
    Utilities.handleEvent('.js-price-list-option', 'click', PriceLists.handleOptionClick);
  }

  static hideFormData(params) {
    const { checkbox, checkboxActive, tab, body, checkboxCount, container } = params;

    checkbox.checked = false;
    if (checkboxActive) checkboxActive.checked = false;

    if (tab.classList.contains('uk-active')) {
      tab.classList.add('uk-hidden');
      tab.classList.remove('uk-active');
      body.classList.add('uk-hidden');
      body.classList.remove('uk-active');

      if (checkboxCount === 1) {
        container.classList.add('uk-hidden');
      } else {
        const tabToShow = document.querySelector('.js-price-list-tab:not(.uk-hidden)');
        const fieldToShow = document.querySelector('.js-price-list-body:not(.uk-hidden)');

        tabToShow.classList.add('uk-active');
        tabToShow.classList.remove('uk-hidden');

        fieldToShow.classList.add('uk-active');
        fieldToShow.classList.remove('uk-hidden');
      }
    } else {
      tab.classList.add('uk-hidden');
      tab.classList.remove('uk-active');
      body.classList.add('uk-hidden');
      body.classList.remove('uk-active');
    }
  }

  static showFormData(params) {
    const { checkbox, checkboxActive, tab, body, checkboxCount, container } = params;
    checkbox.checked = true;
    if (checkboxActive) checkboxActive.checked = true;

    tab.classList.remove('uk-hidden');
    body.classList.remove('uk-hidden');
    if (checkboxCount === 0) {
      body.classList.add('uk-active');
      tab.classList.add('uk-active');
      container.classList.remove('uk-hidden');
    }
  }

  static handleFormsErrors() {
    const invalidForms = Array.from(document.querySelectorAll('.js-price-list-body')).filter(
      (el) => el.dataset.valid === 'false'
    );

    if (invalidForms.length === 0) return;

    invalidForms.forEach((el) => {
      const id = el.getAttribute('data-id');
      const tab = document.querySelector(`.js-price-list-tab[data-id='${id}']`);
      tab.classList.add('tab-error');
    });

    document.querySelectorAll('.js-price-list-tab').forEach((el) => el.classList.remove('uk-active'));
    document.querySelectorAll('.js-price-list-body').forEach((el) => el.classList.remove('uk-active'));

    const firstInvalidTab = document.querySelector('.tab-error');
    const firstInvalidBody = document.querySelector(
      `.js-price-list-body[data-id='${firstInvalidTab.getAttribute('data-id')}']`
    );

    firstInvalidTab.classList.add('uk-active');
    firstInvalidBody.classList.add('uk-active');
  }

  static switcherTabs() {
    document.querySelectorAll('.js-price-list-tab').forEach((el) => el.classList.remove('uk-active'));
    document.querySelectorAll('.js-price-list-body').forEach((el) => el.classList.remove('uk-active'));
    document.querySelector('.js-price-list-tab:not(.uk-hidden)').classList.add('uk-active');
    document.querySelector('.js-price-list-body:not(.uk-hidden)').classList.add('uk-active');
  }

  static handleServiceCheckboxClick() {
    const serviceKind = this.getAttribute('data-service');
    document.querySelector(`.js-price-list-service-${serviceKind}`).classList.toggle('uk-hidden');
  }

  static serviceCheckbox() {
    Utilities.handleEvent('.js-price-list-service-checkbox', 'click', PriceLists.handleServiceCheckboxClick);
  }
}

export default PriceLists;
