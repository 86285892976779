import Utilities from './utilities';

class Shipments {
  static init(root = document) {
    const left = root.querySelector('.js-scroll-left');
    const right = root.querySelector('.js-scroll-right');
    const table = root.querySelector('.js-scroll-table');

    if (!left || !right || !table) return;

    table.scrollLeft = table.scrollWidth;

    const setActiveArrows = () => {
      const maxScrollLeft = table.scrollWidth - table.clientWidth;
      table.scrollLeft === 0 ? left.classList.remove('active') : left.classList.add('active');
      table.scrollLeft === maxScrollLeft ? right.classList.remove('active') : right.classList.add('active');
    };

    setActiveArrows();

    Utilities.handleEvent('.js-scroll-left', 'click', () => table.scrollBy(-100, 0));
    Utilities.handleEvent('.js-scroll-right', 'click', () => table.scrollBy(100, 0));
    Utilities.handleEvent('.js-scroll-table', 'scroll', setActiveArrows);
  }

  static onLoad() {
    const shippingPage = document.querySelector('.js-shippings-page');
    if (!shippingPage) return;

    const companiesButtons = document.querySelectorAll('.js-shipping-button');

    if (companiesButtons.length === 0) return;

    companiesButtons[0].click();
  }

  static handleShipmentsFormSubmit() {
    Utilities.handleEvent('.js-shipments-submit', 'click', function (e) {
      e.preventDefault();

      const form = Utilities.closestParentElement(this, 'form');
      const shouldRefetch = document.querySelector('#shipping_operation_refetch');

      if (shouldRefetch) shouldRefetch.value = false;

      Rails.fire(form, 'submit');
    });
  }
}

export default Shipments;
