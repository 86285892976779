import debounce from 'debounce';

class Utilities {
  static trigger(element, eventType) {
    const event = document.createEvent('HTMLEvents');
    event.initEvent(eventType, true, false);
    element.dispatchEvent(event);
  }

  static setCookie(key, value, days = 10000000000) {
    const date = new Date();
    date.setTime(date.getTime() + days * 86400000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie = key + '=' + value + ';' + expires + ';path=/';
  }

  static getCookie(cookieName) {
    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const arr = decodedCookie.split(';');
    for (let i = 0; i < arr.length; i++) {
      let char = arr[i];
      while (char.charAt(0) == ' ') {
        char = char.substring(1);
      }
      if (char.indexOf(name) == 0) {
        return char.substring(name.length, char.length);
      }
    }
    return '';
  }

  static closestParentElement(elem, type) {
    let parent = elem;
    while (parent !== document.body) {
      parent = parent.parentElement;
      if (parent.nodeName.toLowerCase() === type) {
        return parent;
      }
    }
    return document.body;
  }

  static closestParentWithClass(elem, className) {
    let parent = elem;
    while (parent !== document.body) {
      parent = parent.parentElement;
      if (parent.classList.contains(className)) {
        return parent;
      }
    }
    return document.body;
  }

  static togglePasswordVisibility() {
    const passwordInput = document.querySelector('.js-password-input input');
    this.handleEvent('#js-password-toggle', 'change', () => {
      passwordInput.type === 'password' ? (passwordInput.type = 'text') : (passwordInput.type = 'password');
    });
  }

  static handleInputSubmit() {
    let timeout;
    this.handleEvent('.js-input-submit', 'keyup', function () {
      const form = this.closest('form');
      clearTimeout(timeout);
      timeout = setTimeout(() => Rails.fire(form, 'submit'), 500);
    });
  }

  static handleDropdownSubmit() {
    this.handleEvent('.js-dropdown-select', 'change', function () {
      const form = this.closest('form');
      const url = form.getAttribute('action');
      const method = form.getAttribute('method');

      if (!form || !url || !method) return;

      let params = new URLSearchParams(new FormData(form));
      const additional_attr = this.getAttribute('data-additional-attr');
      const additional_attr_value = this.getAttribute('data-additional-attr-value');

      if (additional_attr) {
        params.set(additional_attr, additional_attr_value);
      }

      Rails.ajax({
        url: url,
        type: method,
        data: params,
      });
    });
  }

  static checkboxSelection() {
    this.handleEvent('.js-select-all-checkbox', 'click', function () {
      document.querySelectorAll('.js-select-row-checkbox:not(.imker-input--disabled)').forEach((checkbox) => {
        checkbox.checked = this.checked;
      });
    });
  }

  static downloadCheckbox() {
    this.handleEvent('.js-download-checkbox', 'click', function () {
      const linkElement = document.querySelector('.js-download-link');
      const selectedDownloadCheckboxesCount = document.querySelectorAll('.js-download-checkbox:not(.js-select-all-checkbox):checked').length;
      const selectAllCheckbox = document.querySelector('.js-select-all-checkbox');

      if (selectedDownloadCheckboxesCount === 0) {
        linkElement.classList.add('uk-disabled');
        selectAllCheckbox.checked = false;
      } else {
        linkElement.classList.remove('uk-disabled');

        const baseUrl = linkElement.getAttribute('data-base-url');
        const idArrayName = linkElement.getAttribute('data-ids-name');
        const ids = Array.from(
          document.querySelectorAll(
            '.js-download-checkbox:not(.js-select-all-checkbox):not(.imker-input--disabled):checked'
          )
        ).map((el) => el.getAttribute('data-download-id'));
        const paramsString = ids.map((id, index) => {
          if (index === 0) return `?${idArrayName}[]=${id}`;

          return `&&${idArrayName}[]=${id}`;
        });

        const newUrl = baseUrl.concat(paramsString.join(''));
        linkElement.setAttribute('href', newUrl);

        const disabledDownloadCheckboxesCount = document.querySelectorAll('.imker-input--disabled.js-download-checkbox:not(.js-select-all-checkbox)').length;
        const downloadCheckboxesCount = document.querySelectorAll('.js-download-checkbox:not(.js-select-all-checkbox)').length;

        if (selectedDownloadCheckboxesCount + disabledDownloadCheckboxesCount == downloadCheckboxesCount) {
          selectAllCheckbox.checked = true;
        } else {
          selectAllCheckbox.checked = false;
        }
      }
    });
  }

  static parseDOM(content, children = false) {
    const domParser = new DOMParser();
    const parsedContent = domParser.parseFromString(content, 'text/html');
    return children ? parsedContent.body.children : parsedContent.body.firstChild;
  }

  static handleEvent(identifier, eventType, callback, root = document) {
    const elements = root.querySelectorAll(identifier);
    if (elements.length === 0) return false;

    elements.forEach(function (elem) {
      elem.removeEventListener(eventType, callback);
      elem.addEventListener(eventType, callback);
    });
  }

  static serializeForm() {
    this.handleEvent('.js-serialize-button', 'click', function () {
      const form = this.closest('form');
      const url = this.getAttribute('data-url');
      const method = this.getAttribute('data-method');
      if (!form || !url || !method) return;

      const params = new URLSearchParams(new FormData(form)).toString();
      Rails.ajax({
        url: url,
        type: method,
        data: params,
      });
    });
  }

  static disableForm() {
    const inputs = Array.from(document.querySelectorAll('.js-disabled-form input'));
    inputs.forEach((input) => input.classList.add('uk-disabled'));
  }

  static handleFileUpload(wrapperElement, filenameElement) {
    const input = wrapperElement.querySelector('input[type="file"]');

    this.handleEvent(
      'button',
      'click',
      function (ev) {
        ev.preventDefault();
        input.click();
      },
      wrapperElement
    );

    this.handleEvent(
      'input[type="file"]',
      'change',
      function () {
        const file = input.files[0];
        filenameElement.innerText = file.name;
      },
      wrapperElement
    );

    this.handleEvent('.js-file-input', 'change', function () {
      const form = document.querySelector('.js-file-form');
      Rails.fire(form, 'submit');
    });
  }

  static itemsSortable () {
    UIKit.util.on('.uk-sortable', 'stop', function(ev) {
      const items = Array.from(ev.target.querySelectorAll('.js-item-sortable'));
      const data = items.map((item, index) => ({ id: item.dataset.id, order: index }));
      const url = ev.target.dataset.url
      const additionalValue = Utilities.parseValue(ev.target.dataset.additionalAttr);
      const method = ev.target.dataset.method

      Rails.ajax({
        url: url,
        type: method,
        beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        options.data = JSON.stringify({ positions: data, additional_attr: additionalValue })
        return true
        },
      });
    });
  }

  static parseValue(strData) {
    try {
        const parsed = JSON.parse(strData);
        return parsed;
    } catch (e) {
        return {};
    }
  }
}

export default Utilities;
