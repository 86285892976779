class Modal {
  showWithPartial(partial) {
    const modalPartial = Utilities.parseDOM(partial);
    const modalId = modalPartial.getAttribute('id');
    let modal = document.getElementById(modalId);

    if (modal) {
      const modalContent = modalPartial.querySelector('.js-modal-content');
      modal.querySelector('.js-modal-content').replaceWith(modalContent);
    } else {
      modal = modalPartial;
    }
    UIKit.modal(modal).show();

    return modal;
  }
}

export default Modal;
