import Utilities from './utilities';

let Rails;
const translations = {
  pl: {
    confirm: 'Zatwierdź',
    cancel: 'Anuluj',
    warning: 'Uwaga',
  },
  en: {
    confirm: 'Confirm',
    cancel: 'Cancel',
    warning: 'Warning',
  },
};

const customModal = (message, opts) => {
  const options = Object.assign({ stack: true, bgClose: false, escClose: true, labels: UIKit.modal.labels }, opts);
  return new Promise((resolve, reject) => {
    const modal = UIKit.modal.dialog(
      `
      <form class="imker-modal">
        <div class="uk-modal-header uk-flex uk-flex-between uk-flex-middle">
          <span class="font-size-large">${options.title}</span>
          <button class="uk-modal-close" type="button"></button>
        </div>
        <div class="uk-modal-body">
          <span>${message}</span>
          <div class="uk-margin-top imker-checkbox uk-margin-medium-bottom ${options.showCheckbox ? '' : 'uk-hidden'}">
            <label for="confirmCheckbox">
                <input id="confirmCheckbox" class="uk-checkbox js-confirm-checkbox uk-margin-remove-top" type="checkbox" />
                <span class="font-size-normal">${options.checkboxMessage ? options.checkboxMessage : ''}</span>
            </label>
           </div>
          <div class="uk-flex uk-flex-wrap uk-flex-right uk-margin-top">
            <button type="button" class="uk-modal-close imker-button imker-button--secondary imker-button--small uk-margin-right">${
              options.labels.cancel
            }</button>
            <button type="submit" class="imker-button imker-button--default imker-button--small">${
              options.labels.ok
            }</button>
          </div>
        </div>
      </form>
    `,
      options
    );

    let resolved = false;

    modal.$el.getElementsByTagName('form')[0].addEventListener('submit', (ev) => {
      ev.preventDefault();
      resolve();
      resolved = true;
      modal.hide();
    });

    UIKit.util.on(modal.$el, 'hide', () => {
      if (!resolved) reject();
    });

    if (options.showCheckbox) {
      Utilities.handleEvent('.js-confirm-checkbox', 'change', function (e) {
        e.target.checked
          ? Utilities.setCookie(options.cookieName, true)
          : Utilities.setCookie(options.cookieName, false);
      });
    }
  });
};

const handleConfirm = function (el) {
  if (!allowAction(this)) Rails.stopEverything(el);
};

const allowAction = (el) => {
  if (el.dataset.uiConfirm === null) return true;
  showConfirmationDialog(el);
  return false;
};

const showConfirmationDialog = (el) => {
  const message = el.dataset.uiConfirm;
  const lang = document.body.dataset.language || 'pl';

  const options = {
    title: el.dataset.uiTitle || translations[lang].warning,
    labels: {
      ok: translations[lang].confirm,
      cancel: translations[lang].cancel,
    },
    showCheckbox: el.dataset.uiShowCheckbox ? JSON.parse(el.dataset.uiShowCheckbox) : false,
    checkboxMessage: el.dataset.uiCheckboxMessage,
    cookieName: el.dataset.uiCookieName,
  };

  customModal(message, options)
    .then(() => confirmed(el))
    .catch(() => {});
};

const confirmed = (el) => {
  el.removeAttribute('data-ui-confirm');
  el.click();
};

const customConfirm = (railsObject, railsStart) => {
  Rails = railsObject;

  document.addEventListener('rails:attachBindings', (el) => {
    Rails.delegate(
      document,
      'a[data-ui-confirm], button[data-ui-confirm], input[data-ui-confirm]',
      'click',
      handleConfirm
    );
  });

  if (Rails.fire(document, 'rails:attachBindings')) railsStart();
};

export default customConfirm;
