// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import UIKit from './libs/uikit.js';
import UIKitIcons from './libs/uikit-icons';
import Utilities from './utilities';
import Modal from './modal';
import Notifications from './notifications';
import General from './general';
import Monthpicker from './monthpicker';
import Datepicker from './datepicker';
import Shipments from './shipments';
import PriceLists from './priceLists';
import Services from './services';
import Tags from './tags';
import customConfirm from './customConfirm';
import Dropdown from './dropdown';
import cocoonVanilaJs from '@kollegorna/cocoon-vanilla-js';
import 'trix';
import '@rails/actiontext';

const modal = new Modal();
const notifications = new Notifications();

Turbolinks.start();
ActiveStorage.start();
UIKit.use(UIKitIcons);

window.Modal = modal;
window.Notifications = notifications;
window.UIKit = UIKit;
window.Rails = Rails;
window.Utilities = Utilities;
window.General = General;
window.Monthpicker = Monthpicker;
window.Datepicker = Datepicker;
window.Shipments = Shipments;
window.PriceLists = PriceLists;
window.Services = Services;
window.Tags = Tags;

customConfirm(Rails, Rails.start);

document.addEventListener('turbolinks:load', function () {
  Utilities.handleInputSubmit();
  Utilities.togglePasswordVisibility();
  Utilities.checkboxSelection();
  Utilities.downloadCheckbox();
  Utilities.itemsSortable();
  Monthpicker.init();
  Datepicker.init();
  General.toggleSidebar();
  General.initChoices();
  General.addLoader();
  Shipments.init();
  Shipments.onLoad();
  PriceLists.toggleForm();
  Dropdown.focusOnSearchInput();
  Services.enableRow();
});
