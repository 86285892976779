import Utilities from './utilities';

class Dropdown {
  static handleShow() {
    const searchInput = document.querySelector('#search_query');
    searchInput && searchInput.focus();
  }

  static focusOnSearchInput() {
    Utilities.handleEvent('.js-clients-dropdown', 'show', Dropdown.handleShow);
  }
}

export default Dropdown;
